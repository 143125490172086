(function($) {

    $(document).ready(function() {

      

        /**
         * RESPONSIVE IFRAME
         * Make iframes added from WordPress responsive
         */
        $('.entry-content').find( 'iframe, embed, object' ).each(function(){
            $(this).wrap( '<div class="embed-responsive embed-responsive-16by9"></div>' );
        });
        // responsive.iframe

        /**
         * SCROLL TO TOP
         */
        $('#back-to').on( 'click', function(){
            var target = $(this.hash);
            $("html, body").animate({scrollTop: 0}, 1000);
        });
        // scroll.to.top

        /*!
         * POP-UP SHARE
         * Open share options on a pop-up window
         * I know it feels like coding in 1999, but
         * it's what most social buttons do anyway.
         */
        $('.entry-share a.sm-share').on('click', function(e)
        {
            e.preventDefault();
            e.stopPropagation();
            var $h = 510,
                $w = 510,
                px = Math.floor( ((screen.availWidth || 1024) - $w) / 2 ),
                py = Math.floor( ((screen.availHeight || 700) - $h) / 2 );
            var popup = window.open( $(this).attr('href'), 'social',
                'width='+$w+',height='+$h+',left='+px+',top='+py+
                ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');
            if ( popup ) {
                popup.focus();
                e.preventDefault();
            }
            return !!popup;
        });

        /**
         * EXTERNAL LINKS
         * Remember to add the icon trougth CSS
         */
        $('body').find('a[href*="http"]').each(function() {
            // Don't run if is from the same domain.
            var a = new RegExp('/' + window.location.host + '/');
            if(a.test(this.href) || $(this).hasClass('skip-external')) {
                return true;
            }
            // Add external rel attribute
            $(this).attr('rel', 'external');
            $(this).attr('title', 'Site externo');
            // Check if has text element
            if ($(this).find('.text') && $(this).find('.text').length > 0) {
                $(this).addClass('skip-withtext');
            }
        });
        // external-site */

        /**
         * GALLERY FILE MODAL
         * Create modal effect for gallery files
         */
        $('.entry-gallery').on('click', 'a', function(e) {
            var $m = $('#modal-gallery'),
            $i = 0,
            $a = $(this),
            $imgs = $(this).parents('.entry-gallery').find('img');
            $caps = $(this).parents('.entry-gallery').find('.entry-caption');
            console.log($($caps[0]).text());
            e.preventDefault();
            e.stopPropagation();
            //$m.find('.modal-body').append($imgs);
            $m.find('.modal-body').html('');
            $m.find('.modal-body').append('<ul class="entry-listing">');
            $imgs.each( function(i, item){
                $id = ' id="'+ $i + '"';
                if ($(item).attr('src') == $a.find('img').attr('src')) {
                    $class = ' active';
                }
                else { $class = ''; }
                $html = '<li'+ $id +' class="entry-listing-item'+ $class +'">';
                $html+= '<img src="'+ $(item).attr('src') +'" alt="'+ $a.find('img').attr('alt') +'" />';
                if($($caps[$i]).text() != '' ) {
                    $html+= '<div class="entry-caption" style="width:'+ $(item).attr('width') +'px">'+ $($caps[$i]).text() +'<div>';
                }
                $html+= '<button class="navigate prev"><span class="sr-only">anterior</span></button>';
                $html+= '<button class="navigate next"><span class="sr-only">próximo</span></button>';
                $html+= '</li>';
                $m.find('.modal-body ul').append($html);
                $i++;
            });
            //$m.find('.modal-body').html('<img src="'+ $a.attr('href') +'" alt="'+ $a.find('img').attr('alt') +'" />');
            $m.modal('show');
        });

        function turn_right( $id ) {

            $next = parseInt($id) + 1;

            if ( $('#'+ $next).length !== 0 ) {
                $( '#'+ $id ).removeClass('active');
                $( '#'+ $next ).addClass('active');
            } else {
                $( '#'+ $id ).removeClass('active');
                $( '#0' ).addClass('active');
            }

        } // turn-right

        function turn_left( $id ) {

            $prev = parseInt($id) - 1;
            $last = 0;

            $('.modal-gallery li').each( function(i, item){
                $last = $(item).attr('id');
            });

            if ( $('#'+ $prev).length !== 0 ) {
                $( '#'+ $id ).removeClass('active');
                $( '#'+ $prev ).addClass('active');
            } else {
                $( '#'+ $id ).removeClass('active');
                $( '#'+ $last ).addClass('active');
            }

        } // turn-left

        $('.modal-gallery').on('click', 'button', function(e) {

            $id = $(this).parents('li').attr('id');
            $button = $(this).hasClass('next');
            if ( $button ) {
                turn_right( $id );
            } else {
                turn_left( $id );
            }

        });

        $('body').on('keydown', function(e){
            if($('.modal-gallery').hasClass('in') && (e.wich == 37 || e.keyCode == 37) ){
                turn_left( $('.modal-gallery li.active').attr('id') );
            }
            if($('.modal-gallery').hasClass('in') && (e.wich == 39 || e.keyCode == 39) ){
                turn_right( $('.modal-gallery li.active').attr('id') );
            }
        });

        // page-menu */

        /**
         * MODAL VIDEO
         * Refresh modal contente when close
         */
        $('[data-target="#modal-video"]').on('click', function (e) {
            $video = $(this).attr('data-video');
            $url = 'https://www.youtube.com/embed/'+ $video +'?rel=0&autoplay=1';
            $('#modal-video .modal-body .embed-responsive-item').attr('src', $url );
        });
        $('#modal-video').on('hidden.bs.modal', function (e) {
            $('#modal-video .modal-body .embed-responsive-item').attr('src','');
        });
        // modal-video */

        /**
         * NAVBAR DROPDOWN
         * Turn on dropdown for the navbar element
         */
        $('.header-menus li.has-children, .header-menu-mobl li.menu-item-has-children, .header-menu-search').each(function(){
            $(this).find('> a').attr('data-toggle','dropdown');
        });
        // ADD SLIDEDOWN ANIMATION TO DROPDOWN //
        $('.header-menus li.has-children, .header-menu-mobl li.menu-item-has-children, .header-menu-search').on('show.bs.dropdown', function(e){
            $(this).find('.sub-menu').first().stop(true, true).slideDown(300);
            $(this).find('a').first().addClass('active');
            $('.menu-search').collapse('hide');
        });
        // ADD SLIDEUP ANIMATION TO DROPDOWN //
        $('.header-menus li.has-children, .header-menu-mobl li.menu-item-has-children, .header-menu-search').on('hide.bs.dropdown', function(e){
            $(this).find('.sub-menu').first().stop(true, true).slideUp(300);
            $(this).find('a').first().removeClass('active');
            $('.menu-search').collapse('hide');
        });

        $('.header-search-open').on('click', function(){
            $('.header-menus').collapse('hide');
        });
        $('.header-brand-open').on('click', function(){
            $('.menu-search').collapse('hide');
        });
        // navbar-dropdown */

        // Sound player
        $('#audio-player').mediaelementplayer({
            alwaysShowControls: true,
            timeFormat: 'mm:ss',
            features: ['playpause', 'progress', 'current', 'duration']
        });

        $('.podcast-links ul li').each(function(k, v){
            $(v).find('a').on('click', function(ev) {
                //ev.preventDefault();
                var url = $(this).attr('href');
                var title = $(this).parent().parent().parent().closest('.entry-link-podcast').find('.entry-title').text();
                var evento = $(this).find('.text').text();
                // console.log(url);
                // console.log(title);
                // console.log(evento);
                //ga('send', 'event', 'Podcast', evento, title);

                ga('send', 'event', 'Podcast', evento, title);
                //check if _gaq is set too
                if (typeof _gaq !== 'undefined') {
                    _gaq.push(['_trackEvent', 'Podcast', evento, title]);
                    console.log('_gaq');
                }
                //check if _gaq is set too
                if (typeof __gaTracker !== 'undefined') {
                    __gaTracker('send', 'event', 'Podcast', evento, title);
                    console.log('__gaTracker');
                }
                //window.location()

                // _gaq.push(['_trackEvent','Publicações','Download','DialogoEscolaFamilia']);
            });
        });


    }); // document.ready

})(jQuery);
